.sf-container {
  width: 620px;
  height: 600px;
  padding: 5px 40px 20px 40px;
}

.sf-title {
  margin: 0 0 20px 0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.sf-container button {
  border: none;
  background-color: #FFFFFF;
  outline: none!important;
}

.sf-time-container {
  display: flex;
  margin: 0 0 5px 0;
}

.sf-calendar {
  width: 400px;
  height: 310px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
}

.react-calendar__navigation {
  height: 40px;
}

abbr {
  text-decoration: auto!important;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__month-view__weekdays {
  background-color: #CCC;
}

.sf-calendar-title {
  height: 40px;
  background-color: #CCC;
  outline: none;
}

.react-calendar__tile--active {
  background-color: #CCCCCC66!important;
}

.sf-time-picker {
  width: 120px;
  height: 310px;
  margin: 0 0 0 20px;
  background-color: #FFFFFF;
  overflow-y: auto;
}

.sf-time-picker-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-bottom: 0.5px solid #CCCCCC66;
  cursor: pointer;
}

.sf-time-picker-item.disabled {
  color: #D4D4D4;
}

.sf-time-picker-item.active {
  background-color: #CCCCCC55
}

.sf-inputs-container {
  display: flex;
  flex-direction: column;
}

.sf-input {
  width: 400px;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.sf-input-error {
  height: 30px;
  color: tomato;
  font-size: 14px;
  font-weight: 300;
}

.sf-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  margin: 0 auto 0 auto;
  border-radius: 100px;
  border: 1px solid #5dfc71!important;
  background-color: #0D0D19!important;
  color: #5dfc71;
  font-weight: 700;
  transition: 0.2s ease;
  outline: none!important;
  cursor: pointer;
}

.sf-submit:disabled {
  border: 1px solid #CCC!important;
}

.sf-submit:hover {
  background-color: #0D0D19;
  color: #5dfc71;
  transform: scale(1.06);
  transition: 0.2s ease;
}