.do-not-support {
    width: 100%;
}

.not-supported-logo {
    height: 100%;
    width: 210px;
    padding: 20px;
}

.not-supported-text {
    display: flex;
    align-items: center;
    margin-top: 25px;
    color: white;
    background: #11171D;
    padding: 15px 10px;
}

.not-supported-text > p {
    padding: 0 10px;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.not-supported-back-space {
    padding-right: 20px;
    margin-top: 25px;
    width: 100%;
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.not-supported-back-button {
    font-weight: 700;
    font-size: 14px;
    padding: 12px 20px;
    min-width: 120px;
    color: white;
    border-radius: 5px;
    background: #0A7FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    outline: none !important;
    border: none;
    white-space: nowrap;
}

.not-supported-back-button:hover {
    background: #4197ff;
    color: white;
    text-decoration: none;
    transition: 0.2s ease;
}