.app-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (min-width: 1400px) {
    .session-wrapper-container {
            height: 100%;
            width: 100%;
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
    }
}

.session-wrapper-container {
    height: 100%;
    width: 100%;
}


.session-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.session-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 0 0 20px;
    scrollbar-color: dark;
}

.s-activity-container {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.s-celebrity-video {
    width: 28vh;
    height: 21vh;
    margin: 0 2% 0 0;
}

.s-celebrity-video.celebrity {
    width: 40vh;
    height: 30vh;
}

.s-player-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0A0D10;
    width: 100%;
}

.s-player-container.placeholder {
    background-color: #2F3540;
    min-height: 320px;
}

@media (min-width: 1280px) {
    .s-player-container.placeholder {
        min-height: 580px;
    }
}

.s-player-container.celebrity {
    height: 63%;
}

.s-player-button-placeholder {
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/player_button.png');
    cursor: pointer;
}

.s-right-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 22%;
    height: 100%;
    padding: 0 20px 0 0;
}

.s-participants-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 90px);
    overflow-y: auto;
    scrollbar-color: dark;
    max-height: calc(100vh - 90px - 75px);
}

.s-participants-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.s-participants-container::-webkit-scrollbar-thumb {
    background: transparent;
}

.s-participants-container.left_marging {
    margin: 0 0 0 30px;
}

.s-participants-container.right_margin {
    padding: 0 0 0 10px;
    margin: 0 30px 0 0;
}

.s-participants-empty-space {
    width: 100%;
    height: 90px;
}

.s-participant-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.s-participant-video-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22vh;
    margin: 0 0 20px 0;
}

.s-participant-video-block.placeholder {
    background-color: #2F3540;
}

.s-participant-video-block-wrapper {
    width: 100%;
    padding: 10px 0;
    margin: 0 0 20px 0;
    /* border-top: 1.5px solid #CCCCCC;
    border-bottom: 1.5px solid #CCCCCC; */
}

.s-participant-video-block.celebrity {
    margin: 0;
}

.s-participant-placeholder {
    width: 120px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../assets/user_icon.png');
}

.s-leave-button {
    margin: 0 0 0 20px;
}

.s-session-footer {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 12%;
    max-height: 12%;
}

.s-session-footer.celebrity {
    flex-direction: column;
    align-items: flex-start;
    min-height: 20%;
    max-height: 20%;
    margin: 2% 0;
    justify-content: flex-start;
}

.s-session-footer table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.s-session-footer table th, tr {
    display: flex;
    width: 100%;
    height: 40px;
}

.s-session-footer table th>td, tr>td {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.s-back-button {
    font-weight: 700;
    font-size: 14px;
    padding: 12px 20px;
    min-width: 120px;
    color: white;
    border-radius: 5px;
    background: #0A7FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    outline: none !important;
    border: none;
    white-space: nowrap;
}

.s-back-button:hover {
    background: #4197ff;
    color: white;
    text-decoration: none;
    transition: 0.2s ease;
}

@media (min-width: 768px) {
    .s-back-button {
        font-size: 14px;
    }
}

@media (min-width: 1280px) {
    .s-back-button {
        font-size: 16px;
    }
}

.s-logo-space {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
}

.s-logo-space > img {
    height: 30px;
    width: auto;
}

.s-back-space {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.copy-link-container {
    background: #11171D;
    width: 100%;
    margin-bottom: 0px;
}

@media (min-width: 1400px) {
    .copy-link-wrapper {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}

.copy-link-part {
    height: 75px;
    padding: 0 20px 0 20px;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copy-link-button {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 12px 20px;
    width: 100%;
    color: white;
    border-radius: 5px;
    background: #0A7FFF;
    transition: 0.2s ease;
    outline: none !important;
    border: none;
    white-space: nowrap;
}

@media (min-width: 768px) {
    .copy-link-button {
        font-size: 12px;
    }

    .copy-link-button-text {
      display: none;
    }

    .copy-link-button-text-tablet {
        display: inline;
    }
}

@media (min-width: 960px) {
    .copy-link-button {
        font-size: 14px;
    }

    .copy-link-button-text {
        display: inline;
    }

    .copy-link-button-text-tablet {
        display: none;
    }
}

@media (min-width: 1280px) {
    .copy-link-button {
        font-size: 16px;
    }

    .copy-link-button-text {
        display: inline;
    }

    .copy-link-button-text-tablet {
        display: none;
    }
}

.copy-link-button:hover {
    background: #4197ff;
    transition: 0.2s ease;
}

.copy-link-header {
    font-family: FIFAnybody, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    margin: 0;
}

@media (min-width: 768px) {
    .copy-link-header {
        font-size: 18px;
    }
}

@media (min-width: 1280px) {
    .copy-link-header {
        font-size: 24px;
    }
}

.paperclip-icon {
    margin-right: 10px;
}