.uda-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.uda-input-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.uda-input-label {
  color: #FFFFFF;
}

.uda-label {
  margin-top: 25px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #878D97;
}

.uda-input {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid white;
  background: #252C37;
  color: #FFFFFF;
  outline: none!important;
}

.uda-input::placeholder {
  font-style: italic;
}

.uda-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uda-button {
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 20px;
  min-width: 120px;
  color: white;
  border-radius: 5px;
  background: #0A7FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  outline: none !important;
  border: none;
  cursor: pointer;
}

.uda-button:hover {
  background: #4197ff;
  transition: 0.2s ease;
}

.uda-button.participant {
  background-color: #0A7FFF;
}

.uda-button.participant:hover:enabled {
  background: #4197ff;
  transition: 0.2s ease;
}

.uda-button.participant:disabled  {
  opacity: 0.40;
  cursor: default;
}

.uda-button.celebrity {
  background-color: #0D0D19;
}

.spinner-border-sm {
  border-width: 0.15em!important;
  margin: 0 5px;
}

.uda-input-error {
  height: 30px;
  margin: 0 0 0px 0;
  color: tomato;
  font-size: 14px;
  font-weight: 300;
}