.sd-button {
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 20px;
  min-width: 120px;
  color: white;
  border-radius: 5px;
  background: #0A7FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  outline: none !important;
  border: none;
}

.sd-button:hover {
  background: #4197ff;
  transition: 0.2s ease;
}

.sd-arrow-right {
  width: 20px;
  height: 20px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/arrow_right_light.png');
  background-position: center;
}

.sd-button:hover .sd-arrow-right {
  /* background-image: url('../../../../assets/arrow_right_black.png'); */
}

.sd-schedule-icon {
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-image: url('../../../../assets/calendar.png');
}

.sd-button:hover .sd-schedule-icon {
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  /* background-image: url('../../../../assets/black_calendar.png'); */
}