.cm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 620px;
  height: 600px;
}

.cm-title {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
}

.cm-button {
  width: 140px;
  margin: 40px 0 0 0;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #5dfc71;
  border-radius: 100px;
  background-color: transparent;
  transition: 0.2s ease;
  color: #5dfc71;
  outline: none!important;
}

.cm-button.active {
  color: #0D0D19;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.cm-button:hover {
  transform: scale(1.05);
  transition: 0.2s ease;
}

.cm-link {
  width: 80%;
  margin: 20px 0 0 0;
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  word-wrap: break-word;
  text-decoration: underline;
  cursor: pointer;
}