.mc-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(225, 225, 225, 0.3); */
    z-index: 11;
}

.mc-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: calc(50% - 200px);
    top: 50%;
    transform: translateY(-50%);
    width: 400px;
    height: 40vh;
    padding: 30px 0;
    border-radius: 5px;
    background-color: #0D0D19;
    box-shadow: 3px 12px 21px 6px rgba(0, 0, 0, 0.49);
    z-index: 10;
    color: #FFFFFF;
    text-align: center;
}

.mc-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mc-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    color: #FFFFFF;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}

.mc-title>.spinner-border-sm {
    border-width: 2px!important;
    margin: 0 10px;
}

.mc-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 10px 0;
    color: #FFFFFF77;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.mc-submit {
    width: 40%;
    height: 40px;
    border: 1px solid #5dfc71;
    border-radius: 100px;
    background-color: transparent;
    color: #FFFFFF;
    font-weight: 600;
    transition: 0.2s ease;
    outline: none!important;
}

.mc-submit:hover {
    transform: scale(1.05);
    transition: 0.2s ease;
}

.mc-submit.top-margin {
    margin: 20px 0 0 0;
}

.mc-input {
    width: 80%;
    height: 50px;
    margin: 20px 0 10px 0;
    padding: 0 20px;
    border: none;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.mc-input-error {
    height: 30px;
    margin: 0 0 20px 0;
    color: tomato;
    font-size: 14px;
    font-weight: 300;
}

.mc-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url('../../assets/x_icon.png');
    cursor: pointer;
}