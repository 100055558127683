.se-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.se-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: calc(50% - 200px);
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
  height: 40vh;
  padding: 30px 0;
  border-radius: 5px;
  background-color: #0D0D19;
  box-shadow: 3px 12px 21px 6px rgba(0, 0, 0, 0.49);
  z-index: 10;
  color: #FFFFFF;
  text-align: center;
}

.se-submit {
  width: 40%;
  height: 40px;
  margin: 20px 0 0 0;
  border: 1px solid #5dfc71;
  border-radius: 100px;
  background-color: transparent;
  color: #FFFFFF;
  font-weight: 600;
  transition: 0.2s ease;
  outline: none!important;
}

.se-submit:hover {
  transform: scale(1.05);
  transition: 0.2s ease;
}