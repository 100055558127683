.s-session-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 0 20px 0;
    color: #FFFFFF;
    overflow-y: auto;
}

.s-session-table tr {
    display: flex;
    width: 100%;
    height: 40px;
}

.s-session-table tr>th, tr>td {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    text-align: center;
}

.s-session-table_connect {
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid #5dfc71;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 12px;
    outline: none!important;
}

.s-session-table_connect:hover {
    color: #FFFFFF;
    transform: scale(1.06);
    transition: 0.2s ease;
}

.s-session-table_connect.connected {
    color: #CB3434;
    border: 1px solid #CB3434;
}