.sd-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000095;
    z-index: 11;
}

.sd-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: calc(50% - 200px);
    top: 50%;
    transform: translateY(-50%);
    min-width: 400px;
    max-width: 515px;
    min-height: 260px;
    padding: 30px 42px;
    border-radius: 5px;
    background: #374151;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    z-index: 10;
    color: white;
}

.sd-header {
    text-align: center;
    font-family: FIFAnybody, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}
.sd-text {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
}

.sd-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url('../../assets/x_icon.png');
    cursor: pointer;
}