.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid gray;
    border-radius: 5px;
}

.control-video-block {
    position: absolute;
    height: 30px;
    bottom: 0px;
    min-width: 120px;
    background-color: #f7f5fb7a;
    display: flex;
    border-radius: 0 5px 0 4px;
}

.participant-camera {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.participant-camera img {
    width: 20px;
    height: 20px;
}

.participant-mic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.participant-mic img {
    width: 20px;
    height: 20px;
}

.active-speaker {
    border: 1px solid #0A7FFF;
    border-radius: 5px;
}

.participant-name {
    display: flex;
    align-items: center;
    color: #636467;
    font-size: 12px;
}

.video-icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../assets/camera.svg');
}

.video-icon.DISABLED {
    background-image: url('../../assets/camera-off.svg');
}

.mic-icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../assets/mic.svg');
}

.mic-icon.DISABLED {
    background-image: url('../../assets/mic-off.svg');
}