.sfb-buttons-container {
    display: flex;
}

.sfb-buttons-container.celebrity {
    width: 100%;
}

.sfb-footer-buttons {
    display: flex;
}

.sfb-button {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    outline: none!important;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    transition: transform 0.2s ease;
}

@media (min-width: 768px) {
    .sfb-button {
        font-size: 16px;
    }
}

@media (min-width: 1280px) {
    .sfb-button {
        font-size: 18px;
    }
}

.sfb-button:hover > .arrow-icon {
    transform: translateX(-5px);
    transition: transform 0.2s ease;
}

.arrow-icon {
    margin-right: 10px;
    transition: transform  0.2s ease;
}

.sfb-button.active {

}

.sfb-button:hover {
    transition: 0.2s ease;
}