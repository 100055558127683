.player-container {
  width: 100%;
  height: 100%;
}

.player-container div {
  overflow: hidden;
  position: relative;
  width:100%;
}

.player-container div::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.player-container div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 600px;
}